import { gethost, getToken, callAjax } from './app'

const urlConnect = {
  getRole: '/api/v1/user/get-role',
}

export function getRole(conf) {
  const token = getToken()
  if (token.token) {
    if (!conf.data) {
      conf.data = {}
    }
    conf.data.session = token.session
    conf.data.token = token.token
    callAjax(gethost() + urlConnect.getRole, conf)
  } else {
    return false;
  }
}
