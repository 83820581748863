<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="@/assets/image/404.gif"
          class="my-3"
          contain
          height="200"
          @click="updateRole()"
        />
      </v-col>

      <v-col class="mb-4">
        <p class="subheading font-weight-regular">
          Trang này không tồn tại hoặc có thể đang sửa chữa hãy truy cập lại sau
          <br />
          Truy cập vào
          <router-link to="/" style="text-decoration: none;">trang chủ</router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRole } from '@/helper/center'

export default {
  name: "UpdateRole",
  methods: {
    updateRole() {
      getRole({
        done: data => {
          console.log(data)
        },
        error: res => {
          console.log('Gửi thất bại', res)
        }
      })
    }
  }
};
</script>
